import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "perfiles", "listarGestor"),
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "perfiles", "obtenerDetalle"),
  obtenerPermisos: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "perfiles", "obtenerPermisos"),

  // POST
  agregar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "perfiles", "agregar"),
  editar: (data) => RepoUtils.ejecutarPeticionPost(data, "perfiles", "editar"),
  eliminar: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "perfiles", "eliminar"),
  editarPermisos: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "perfiles", "editarPermisos"),
};
