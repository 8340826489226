
import PerfilService from "../../../Services/PerfilService";
export default {
  name: "ProductoGestor",
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Configuración" }, { etiqueta: "Perfiles" }],
      statusOpciones: [
        { value: 200, label: "Activo" },
        { value: 300, label: "Eliminado" },
      ],
      filtros: {
        busqueda: "",
        status: [],
      },
      perfiles: [],
      columnas: [
        {
          name: "nombre",
          header: "Nombre",
          align: "left",
          field: "titulo",
          minWidth: 120,
          width: 140,
          templateName: "folio",
          routeName: "perfilesDetalle",
          rutaId: "perfil_id",
        },
        {
          name: "descripcion",
          header: "Descripción",
          align: "left",
          field: "descripcion",
          minWidth: 160,
          width: 400,
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 120,
          width: 140,
          templateName: "status",
          statusName: "status_nombre",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 120,
          width: 155,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 130,
          width: 160,
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      agregarPerfilObj: {
        nombre: "",
        descripcion: "",
      },
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerPerfiles(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        status: this.obtenerArrayParametrosQuery(query.s, true), // El segundo parametro es para arrays con valores numericos
        ordenar: "titulo_asc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.status,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerPerfiles(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await PerfilService.listarGestor(this.filtros)
        .then((resp) => {
          let perfiles = resp;
          this.perfiles = perfiles.map((p) => {
            return {
              ...p,
              registro_fecha: new Date(p.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async abrirModalAgregar() {
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.agregarPerfilObj = {
        nombre: "",
        descripcion: "",
      };
      this.modalAgregar = false;
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [],
        ordenar: "titulo_asc",
      };
      this.obtenerPerfiles();
      this.accionLimpiar = true;
    },
    async agregarPerfil(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ...this.agregarPerfilObj,
      };
      await PerfilService.agregar(datos)
        .then(async (resp) => {
          this.cerrarModalAgregar();
          await this.obtenerPerfiles(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
  },
};
